<template>
  <div>
    <shop-head currentPageName="Cart"></shop-head>
    <!-- 面包屑 -->
    <breadcrumb pageName="Cart"></breadcrumb>
    <!-- 表格 -->
    <div class="cart-area">
      <div class="container">
        <!-- 表格 -->
        <el-table
          :data="cartList"
          border
          empty-text="no data"
          @selection-change="handleSelectionChange"
          style="width: 100%"
        >
          <el-table-column
            type="selection"
            width="40"
            align="center"
          ></el-table-column>
          <el-table-column
            type="index"
            label="sort"
            width="55"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="image"
            label="Images"
            min-width="150"
            align="center"
          >
            <template slot-scope="scope">
              <el-image
                v-if="scope.row.imgUrl"
                :src="scope.row.imgUrl"
                :preview-src-list="scope.row.imageList"
                class="table-img"
              >
              </el-image>
              <img
                v-else
                src="../../assets/images/empty-img.jpg"
                alt="productImg"
                class="table-img"
              />
            </template>
          </el-table-column>
          <el-table-column
            prop="partsName"
            label="name"
            min-width="150"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="partsNo"
            label="code"
            min-width="150"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="manufacturerCname"
            label="brand"
            min-width="100"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="sellPrice"
            label="Unit Price"
            min-width="100"
            align="center"
          >
            <template slot-scope="scope">
              <span>${{ $utils.keepTwoDecimalFull(scope.row.sellPrice) }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="quantity"
            label="Quantity"
            min-width="130"
            align="center"
          >
            <template slot-scope="scope">
              <el-input-number
                @change="saveCartData(scope.row)"
                v-model="scope.row.quantity"
                controls-position="right"
                :min="1"
                style="width: 100px"
              ></el-input-number>
            </template>
          </el-table-column>
          <el-table-column
            prop="total"
            label="Total"
            min-width="100"
            align="center"
          >
            <template slot-scope="scope">
              <span
                >${{
                  $utils.keepTwoDecimalFull(
                    $utils.accMul(scope.row.sellPrice, scope.row.quantity)
                  )
                }}</span
              >
            </template>
          </el-table-column>
          <el-table-column
            prop="remove"
            label="Remove"
            width="90"
            align="center"
          >
            <template slot-scope="scope">
              <el-link
                :underline="false"
                type="danger"
                @click="deletCartProduct(scope.row)"
                ><i class="el-icon-delete-solid" style="font-size: 20px"></i>
              </el-link>
            </template>
          </el-table-column>
        </el-table>
        <!-- 优惠券 -->
        <div class="coupon-box">
          <div>
            <el-input
              v-model="couponCode"
              class="coupon-input"
              placeholder="Coupon Code"
            ></el-input>
            <div class="coupon-btn">Apply coupon</div>
          </div>
          <div class="coupon-btn">Update cart</div>
        </div>
        <el-row justify="end" type="flex">
          <el-col :sm="10">
            <div class="cart-page-total">Cart totals</div>
          </el-col>
        </el-row>
        <el-row justify="end" type="flex">
          <el-col :sm="10">
            <ul>
              <li>
                Subtotal
                <span>${{ $utils.keepTwoDecimalFull(subtotal) }}</span>
              </li>
              <li>
                Total
                <span>${{ $utils.keepTwoDecimalFull(subtotal) }}</span>
              </li>
            </ul>
            <div @click="gotoPay" class="coupon-btn">Proceed to checkout</div>
          </el-col>
        </el-row>
      </div>
    </div>
    <common-footer></common-footer>
  </div>
</template>

<script>
import shopHead from "../../components/shop/head";
import commonFooter from "../../components/common/footer";
import breadcrumb from "../../components/common/breadcrumb";
import { getCartList, updateById, deleteById } from "@/api/service/commonApi";
import { mapActions } from "vuex";
export default {
  components: {
    shopHead,
    commonFooter,
    breadcrumb,
  },
  data() {
    return {
      couponCode: "",
      selectData: [],
    };
  },
  computed: {
    subtotal() {
      let count = 0;
      this.cartList.forEach((item) => {
        count += this.$utils.accMul(item.quantity, item.sellPrice);
      });
      return count;
    },
    cartList() {
      return this.$store.state.cartList;
    },
  },
  created() {
    this.initData();
  },
  methods: {
    ...mapActions(["getCartList"]),
    /** 初始化购物车数据 */
    initData() {
      this.getCartList();
    },
    handleSelectionChange(data) {
      this.selectData = data;
    },
    gotoPay() {
      if (this.selectData.length === 0) {
        this.$message.warning("Please select an order item");
        return;
      }
      let id = [];
      let obj = {};
      this.selectData.forEach((item) => {
        id.push(item.id);
        obj[item.id] = {
          num: item.quantity,
        };
      });
      window.sessionStorage.setItem("payItemId", id.join());
      window.sessionStorage.setItem("payItemNum", JSON.stringify(obj));
      window.sessionStorage.setItem(
        "payItemParts",
        JSON.stringify(this.selectData)
      );
      this.$router.push({
        path: "/payOrder",
      });
    },
    /** 删除 */
    deletCartProduct(item) {
      deleteById({
        id: item.id,
      }).then((res) => {
        let data = res.data;
        if (res.data.bizCode.code === "0") {
          this.$message.success("Deleted");
          this.initData();
        } else {
          this.$message.error(res.data.bizCode.info);
        }
      });
    },
    /** 保存购物车数据 **/
    saveCartData(item) {
      updateById({
        id: item.id,
        quantity: item.quantity,
      }).then((res) => {
        let data = res.data;
        if (res.data.bizCode.code === "0") {
          this.$message.success("Modified quantity");
        } else {
          this.$message.error(res.data.bizCode.info);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.breadcrumb-area {
  border-top: 1px solid #ebebeb;
  padding-top: 25px;

  li {
    display: inline-block;
    padding: 0 10px;
    position: relative;

    &:first-child {
      padding-left: 0;
    }

    a {
      color: #333;
    }
  }
}

.cart-area {
  padding: 60px 0;

  ::v-deep .el-table {
    color: #333;
    font-size: 16px;
    font-weight: 500;

    thead {
      color: #333;
    }
  }

  .table-img {
    width: 100px;
  }

  .coupon-box {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .coupon-input {
      width: 120px;
      margin-top: 30px;
      vertical-align: middle;
      margin-right: 5px;

      ::v-deep .el-input__inner {
        &:focus {
          border-color: #fed100;
        }
      }
    }
  }

  .coupon-btn {
    width: auto;
    height: 40px;
    line-height: 40px;
    font-size: 13px;
    font-weight: 700;
    color: #ffffff;
    background-color: #333333;
    border: 0 none;
    border-radius: 2px;
    display: inline-block;
    cursor: pointer;
    letter-spacing: 1px;
    padding: 0 25px;
    text-transform: uppercase;
    vertical-align: middle;
    margin-top: 30px;

    &:hover {
      background: #fed100;
    }
  }

  .cart-page-total {
    padding-top: 30px;
    font-size: 25px;
    font-weight: 700;
    margin-bottom: 20px;
    text-transform: capitalize;
  }

  ul {
    border: 1px solid #ebebeb;

    li {
      list-style: none;
      font-size: 15px;
      padding: 10px 30px;
      border-bottom: 1px solid #ebebeb;
      font-weight: 700;

      &:last-child {
        border: 0;
      }

      span {
        float: right;
      }
    }
  }
}
</style>
